<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogVisible"
    title="添加人员"
  >
    <div style="margin-bottom: 10px">
      <el-input
        placeholder="姓名/身份证号"
        @keyup.enter="getList()"
        v-model="salaryName"
        style="width: 260px"
        size="small"
      />
      <el-button @click="getList()" type="primary" size="small" icon="Search">
        搜索
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="list"
      border
      fit
      highlight-current-row
      :height="500"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" align="center" width="40" />
      <el-table-column
        label="序号"
        prop="id"
        align="center"
        width="45"
        type="index"
      ></el-table-column>
      <el-table-column label="姓名" align="center" min-width="90" prop="name">
      </el-table-column>
      <el-table-column
        label="证件类型"
        align="center"
        min-width="100"
        prop="iDType"
      >
      </el-table-column>
      <el-table-column
        label="证件号码"
        align="center"
        min-width="100"
        prop="iDNo"
      >
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="savePersonal" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { salaryEmpList, saveEaSalary } from "@/api/salary.js";
import { ElMessage } from "element-plus";
const emit = defineEmits();
const props = defineProps({
  param: {
    type: Object,
    default: {},
  },
});
const dialogVisible = ref(false);
const list = ref([]);
const loading = ref(false);
const salaryName = ref("");
const sels = ref([]);
const handleSelectionChange = (val) => {
  let arr = [];
  val.map((item) => {
    arr.push({ sheetId: props.param.sheetId, employeeId: item.id });
  });
  sels.value = arr;
};
const openDialog = () => {
  dialogVisible.value = true;
  getList();
};
const getList = () => {
  loading.value = true;
  salaryEmpList({
    sheetId: props.param.sheetId,
    salaryName: salaryName.value,
    period: props.param.period,
    comId: props.param.comId,
  }).then((res) => {
    loading.value = false;
    list.value = res.data.data.list ? res.data.data.list : [];
  });
};
const savePersonal = () => {
  if (sels.length == 0) {
    ElMessage.warning("请选择人员");
    return;
  }
  let param = {
    list: sels.value,
    period: props.param.period,
    comId: props.param.comId,
  };
  saveEaSalary(param).then((res) => {
    dialogVisible.value = false;
    if (res.data.msg == "success") {
      ElMessage.success("添加成功");
      emit("success");
    }
  });
};
defineExpose({
  openDialog,
});
</script>
