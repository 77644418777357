<template>
  <div>
    <div class="top_title3">
      <h4>全年一次性奖金</h4>
      <h5>{{ period }}</h5>
    </div>
    <el-table stripe :data="list" border v-loading="listLoading" :height="contentStyleObj" style="width: 100%;margin-top: 10px; " show-summary>
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column align="center" type="index" fixed width="50" label="序号"> </el-table-column>
    <el-table-column prop="name" label="姓名" fixed width="100" align="center"></el-table-column>
    <el-table-column align="center" label="证件号码" min-width="190">
      <template #default="{row}">
        <span>{{row.idNo}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="qnycxjje" label="全年一次性奖金额" min-width="220">
      <template #default="{row}">
        <span>{{row.qnycxjje}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="mssr" label="免税收入" min-width="120">
      <template #default="{row}">
        <span>{{row.mssr}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="qt" label="其他" min-width="120">
      <template #default="{row}">
        <span>{{row.qt}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="jze" label="准予扣除的捐赠额" min-width="120">
      <template #default="{row}">
        <span>{{row.jze}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="tax" label="税额" min-width="120">
      <template #default="{row}">
        <span>{{row.tax}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="jmse" label="减免税额" min-width="120">
      <template #default="{row}">
        <span>{{row.jmse}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="remark" label="备注" min-width="120">
      <template #default="{row}">
        <span>{{row.remark}}</span>
      </template>
    </el-table-column>
  </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { salaryOnlyList } from "@/api/salary.js"
export default {
  name:'bonus',
  props: {
    comId:{
      type:Number,
      default:0
    },
    period:{
      type:String,
      default:""
    },
    sheetId:{
      type:Number,
      default:0
    },
  },
  data(){
    return {
      list:[],
      listLoading:false,
      listQuery: {
        page: 1,
        limit: 20
      },
      total:0,
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(230)
  },
  methods:{
    getList(e) {
      this.listLoading = true;
      let param = {
        sheetId: this.sheetId,
        comId: this.comId,
      }
      salaryOnlyList(param).then(res => {
        this.listLoading = false;
        if(res.data.msg == "success"){
          this.list = res.data.data.list
          this.total = res.data.data.total
          //console.log(this.list);
        }else{
          this.list = []
        }
      });
    },
  }
}
</script>

<style>

</style>