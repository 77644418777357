<template>
  <div class="top_select">
    <div>
      <el-input
        placeholder="请输入姓名"
        v-model="listQuery.name"
        style="width: 100px"
        size="small"
        clearable
        @keyup.enter="getList()"
      />
      <el-button @click="getList()" type="primary" size="small" icon="Search"
        >查询
      </el-button>
      <el-select
        v-model="listQuery.feeType"
        clearable
        placeholder="费用类型"
        @change="getList()"
        style="width: 100px"
        filterable
        size="small"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div>
      <el-tooltip
        class="item"
        effect="dark"
        content="同步员工专项附加 "
        placement="top"
      >
        <el-button
          type="primary"
          size="small"
          @click="saveAll('同步')"
          icon="Connection"
          >同步</el-button
        >
      </el-tooltip>
      <el-dropdown
        v-if="$buttonStatus('xc_gzb_tj')"
        split-button
        type="success"
        @click="addPersonal"
        size="small"
        style="margin-right: 10px; margin-left: 10px"
        :hide-on-click="false"
      >
        <i class="iconfont icon-jiahao"></i> 添加
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <span size="small" @click="openCom">薪资导入</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-button type="primary" size="small" @click="allShow" icon="Tickets"
        >修改费用类型
      </el-button>
      <el-button
        v-loading.fullscreen.lock="fullscreenLoading"
        type="danger"
        size="small"
        @click="delAll()"
        plain
        icon="Delete"
      >
        删除
      </el-button>
      <el-dropdown style="margin-left: 6px" size="small">
        <el-button type="primary" size="small">
          更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="a" @click="printingA4()">
              <span>打印A4</span>
            </el-dropdown-item>
            <el-dropdown-item command="b" @click="printingA5()">
              <span>打印凭证纸</span>
            </el-dropdown-item>
            <el-dropdown-item command="c" @click="GetInportListClick()">
              <span>导出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
  <el-table
    stripe
    :data="list"
    v-loading="isLoading"
    :height="contentStyleObj"
    @selection-change="handleSelectionChange"
    style="width: 100%"
    show-summary
  >
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column align="center" type="selection" width="55">
    </el-table-column>
    <el-table-column align="center" type="index" fixed width="70" label="序号">
    </el-table-column>
    <el-table-column prop="name" label="姓名" fixed width="100" align="center">
      <template #default="{ row }">
        <el-tooltip :content="row.idNo" placement="top" effect="dark">
          {{ row.name }}
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column label="费用类型" width="160" align="center">
      <template #header>
        <span>费用类型</span>
        <el-tooltip
          content="点击将第一行费用类型复制到其他行次"
          placement="top"
          effect="dark"
        >
          <i
            @click="selectAllMoney('feeType')"
            class="iconfont icon-piliangcaozuo"
          ></i>
        </el-tooltip>
      </template>
      <template #default="{ row }">
        <el-select v-model="row.feeType" filterable size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column label="应发工资" align="center">
      <el-table-column
        align="center"
        label="工资"
        width="120"
        prop="salary"
        sortable
      >
        <template #header>
          <span>工资</span>
          <el-tooltip
            content="点击将第一行工资复制到其他行次"
            placement="top"
            effect="dark"
          >
            <i
              @click="selectAllMoney('salary')"
              class="iconfont icon-piliangcaozuo"
            ></i>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <qzf-input v-model:num="row.salary" style="width: 100px" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="福利费" width="120" prop="subsidy">
        <template #header>
          <span>福利费</span>
          <el-tooltip
            content="点击将第一行福利费复制到其他行次"
            placement="top"
            effect="dark"
          >
            <i
              @click="selectAllMoney('subsidy')"
              class="iconfont icon-piliangcaozuo"
            ></i>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <qzf-input v-model:num="row.subsidy" style="width: 100px" />
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column
      align="center"
      label="本期免税收入"
      width="120"
      prop="mssr"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.mssr" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column label="代扣社保" align="center">
      <el-table-column
        align="center"
        prop="deductSbPension"
        label="养老保险"
        width="120"
      >
        <template #header>
          <span>养老保险</span>
          <el-tooltip
            content="点击将第一行养老保险复制到其他行次"
            placement="top"
            effect="dark"
          >
            <i
              @click="selectAllMoney('deductSbPension')"
              class="iconfont icon-piliangcaozuo"
            ></i>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <qzf-input v-model:num="row.deductSbPension" style="width: 100px" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="deductSbMedical"
        label="医疗保险"
        width="120"
      >
        <template #header>
          <span>医疗保险</span>
          <el-tooltip
            content="点击将第一行医疗保险复制到其他行次"
            placement="top"
            effect="dark"
          >
            <i
              @click="selectAllMoney('deductSbMedical')"
              class="iconfont icon-piliangcaozuo"
            ></i>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <qzf-input v-model:num="row.deductSbMedical" style="width: 100px" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="deductSbUnemployment"
        label="失业保险"
        width="120"
      >
        <template #header>
          <span>失业保险</span>
          <el-tooltip
            content="点击将第一行失业保险复制到其他行次"
            placement="top"
            effect="dark"
          >
            <i
              @click="selectAllMoney('deductSbUnemployment')"
              class="iconfont icon-piliangcaozuo"
            ></i>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <qzf-input
            v-model:num="row.deductSbUnemployment"
            style="width: 100px"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="deductSbTotal"
        label="合计"
        width="120"
      >
        <template #default="{ row }">
          <span>{{
            (
              row.deductSbPension +
              row.deductSbMedical +
              row.deductSbUnemployment
            ).toFixed(2)
          }}</span>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column
      align="center"
      prop="deductHouseFund"
      label="代扣公积金"
      width="120"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.deductHouseFund" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="childrenEducation"
      label="累计子女教育"
      width="120"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.childrenEducation" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="continuingEducation"
      label="累计继续教育"
      width="120"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.continuingEducation" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="housingLoan"
      label="累计住房贷款"
      width="120"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.housingLoan" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="housingRent"
      label="累计住房租金"
      width="120"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.housingRent" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="support"
      label="累计赡养老人"
      width="120"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.support" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="babyReduction"
      label="累计婴幼儿照护费用"
      min-width="170"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.babyReduction" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="personalPension"
      label="个人养老金"
      min-width="120"
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.personalPension" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="deductOther"
      label="其他扣除"
      width="120"
    >
      <template #header>
        <span>其他扣除</span>
        <el-tooltip
          content="点击将第一行其他扣除复制到其他行次"
          placement="top"
          effect="dark"
        >
          <i
            @click="selectAllMoney('deductOther')"
            class="iconfont icon-piliangcaozuo"
          ></i>
        </el-tooltip>
      </template>
      <template #default="{ row }">
        <qzf-input v-model:num="row.deductOther" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="deductIncomeTax"
      label="本期代扣个税"
      width="120"
      sortable
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.deductIncomeTax" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="actualSalary"
      label="实发工资"
      width="120"
      sortable
    >
      <template #default="{ row }">
        <qzf-input v-model:num="row.actualSalary" style="width: 100px" />
      </template>
    </el-table-column>
    <el-table-column align="center" prop="remark" label="备注" width="120">
      <template #default="{ row }">
        <el-input v-model="row.remark" style="width: 100px" size="small" />
      </template>
    </el-table-column>
    <el-table-column align="center" label="操作" width="120">
      <template #default="{ row }">
        <el-tooltip
          content="保存"
          placement="top"
          v-if="$buttonStatus('xc_gzb_tj')"
          effect="dark"
        >
          <i
            class="iconfont icon-baocun"
            style="cursor: pointer"
            @click="saveAdd(row)"
          ></i>
        </el-tooltip>
        <el-tooltip
          content="删除"
          placement="top"
          v-if="$buttonStatus('xc_gzb_sc')"
          effect="dark"
        >
          <i class="iconfont icon-shanchu" @click="handleModifyStatus(row)"></i>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <div class="save">
    <el-button
      v-loading.fullscreen.lock="fullscreenLoading"
      type="success"
      size="small"
      @click="saveAll()"
      icon="Finished"
    >
      批量保存
    </el-button>
  </div>
  <batchCostType ref="BatchCostType" @typeChange="editType" />
  <printSalaryA4 ref="PrintSalaryA4" :param="listQuery" />
  <print-setting ref="printSetting" @success="printSettingA5"></print-setting>
  <exportEmployee
    ref="ExportEmployee"
    :comId="props.comId"
    :period="props.period"
    :listQuery="listQuery"
    type="salary"
  ></exportEmployee>
  <salaryAddPerson
    ref="SalaryAddPerson"
    :param="listQuery"
    @success="getList"
  />
  <qzf-import-back
    :hideButton="true"
    ref="importBck"
    @success="getList()"
    from="薪酬"
    @success2="getCallList"
    :comId="props.comId"
    :period2="props.period"
    :sheetId="props.sheetId"
  ></qzf-import-back>
  <daoruHint ref="DaoruHint" />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { eaSalaryList, saveEaSalary, delSalary } from "@/api/salary.js";
import { exportSalary } from "@/api/export";
import { printSalary } from "@/api/printSet";
import batchCostType from "@/components/batchEmployeeSet/batchCostType.vue";
import printSalaryA4 from "@/components/batchEmployeeSet/printSalaryA4.vue";
import exportEmployee from "@/components/batchEmployeeSet/exportEmployee.vue";
import salaryAddPerson from "@/components/batchEmployeeSet/salaryAddPerson.vue";
import daoruHint from "../../input/remuneration/components/daoruHint.vue";
import { COST_TYPE_OPTION } from "@/utils/commonData";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const props = defineProps({
  comId: {
    type: Number,
    default: 0,
  },
  period: {
    type: String,
    default: "",
  },
  sheetId: {
    type: Number,
    default: 0,
  },
});
const list = ref([]);
const sels = ref([]);
const ids = ref([]);
const isLoading = ref(false);
const contentStyleObj = ref({});
const fullscreenLoading = ref(false);
const listQuery = ref({
  sheetId: props.sheetId,
  name: "",
  feeType: "",
  comId: props.comId,
  period: props.period,
});
const options = COST_TYPE_OPTION;
const BatchCostType = ref();
const PrintSalaryA4 = ref();
const ExportEmployee = ref();
const SalaryAddPerson = ref();
const importBck = ref();
const DaoruHint = ref();
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(250);
  getList();
});
const getList = () => {
  isLoading.value = true;
  eaSalaryList(listQuery.value).then((res) => {
    isLoading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
    }
  });
};
const handleSelectionChange = (e) => {
  sels.value = e;
  ids.value = e.map((v) => {
    return { id: v.id };
  });
};
//添加人员
const addPersonal = () => {
  SalaryAddPerson.value.openDialog();
};
//导入
const openCom = () => {
  importBck.value.handImport();
};
const getCallList = (e) => {
  DaoruHint.value.init(e);
};
//批量修改费用类型
const allShow = () => {
  if (sels.value.length == 0) {
    proxy.$message.error("请至少选择一条");
    return;
  }
  BatchCostType.value.init();
};
const editType = (e) => {
  list.value.map((v) => {
    ids.value.map((k) => {
      if (k.id == v.id) {
        v.feeType = e;
      }
    });
  });
};
//删除单条
const handleModifyStatus = (row) => {
  let param = {
    comId: props.comId,
    period: props.period,
    list: [{ id: row.id }],
  };
  delSalary(param).then((res) => {
    if (res.data.msg == "success") {
      proxy.$qzfMessage("删除成功");
      getList();
      emit("success");
    }
  });
};
//批量删除
const delAll = () => {
  if (ids.value.length == 0) {
    proxy.$qzfMessage("至少选择一名员工", 1);
    return;
  }
  let param = {
    comId: props.comId,
    period: props.period,
    list: ids.value,
  };
  delSalary(param).then((res) => {
    if (res.data.msg == "success") {
      proxy.$qzfMessage("删除成功");
      getList();
      emit("success");
    }
  });
};
//批量赋值
const selectAllMoney = (e) => {
  if (!list.value[0]) return;
  list.value.map((v) => {
    v[e] = list.value[0][e];
  });
};
//保存单条
const saveAdd = (row) => {
  row.salary = Number(row.salary);
  row.deductSbPension = Number(row.deductSbPension);
  row.deductSbMedical = Number(row.deductSbMedical);
  row.deductSbUnemployment = Number(row.deductSbUnemployment);
  row.deductSbTotal = Number(row.deductSbTotal);
  row.deductHouseFund = Number(row.deductHouseFund);
  row.deductOther = Number(row.deductOther);
  row.deductIncomeTax = Number(row.deductIncomeTax);
  row.actualSalary = Number(row.actualSalary);
  row.subsidy = Number(row.subsidy);
  let param = {
    list: [row],
    period: props.period,
    comId: props.comId,
  };
  saveEaSalary(param).then((res) => {
    if (res.data.msg == "success") {
      proxy.$qzfMessage("保存成功");
      getList();
      emit("success");
    }
  });
};
//保存
const saveAll = (type) => {
  list.value.map((v) => {
    v.salary = Number(v.salary);
    v.subsidy = Number(v.subsidy);
    v.deductSbPension = Number(v.deductSbPension);
    v.deductSbMedical = Number(v.deductSbMedical);
    v.deductSbUnemployment = Number(v.deductSbUnemployment);
    v.deductHouseFund = Number(v.deductHouseFund);
    v.childrenEducation = Number(v.childrenEducation);
    v.continuingEducation = Number(v.continuingEducation);
    v.seriousIllness = Number(v.seriousIllness);
    v.housingLoan = Number(v.housingLoan);
    v.housingRent = Number(v.housingRent);
    v.support = Number(v.support);
    v.babyReduction = Number(v.babyReduction);
    v.deductOther = Number(v.deductOther);
    v.deductIncomeTax = Number(v.deductIncomeTax);
    v.actualSalary = Number(v.actualSalary);
    v.personalPension = Number(v.personalPension);
  });
  let param = {
    list: list.value,
    period: props.period,
    comId: props.comId,
  };
  fullscreenLoading.value = true;
  saveEaSalary(param).then((res) => {
    fullscreenLoading.value = false;
    if (res.data.msg == "success") {
      let text = type ? "同步成功" : "保存成功";
      proxy.$qzfMessage(text);
      getList();
      emit("success");
    }
  });
};
//打印
const printingA4 = () => {
  PrintSalaryA4.value.init();
};
//打印凭证纸
const printingA5 = () => {
  proxy.$refs.printSetting.dayinCustomStatus = true;
};
const printSettingA5 = (params) => {
  params.comIds = [props.comId];
  params.period = props.period;
  params.bookNames = ["salray"];
  params.query = listQuery.value;
  params.type = "salary";
  printSalary(params).then((res) => {
    if (res.data.msg == "success") {
      window.open(res.data.data.url);
    }
  });
};
//导出
const GetInportListClick = () => {
  ExportEmployee.value.init(sels.value);
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iconfont {
  margin-left: 4px;
  cursor: pointer;
}
.save {
  margin-top: 20px;
  text-align: right;
}
.icon-baocun {
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.icon-shanchu {
  color: #fe696a;
  font-size: 18px;
  margin-left: 5px;
}
</style>
