<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogVisible"
    title="查看的报表"
    width="60%"
  >
    <el-table :data="list" border height="400px" v-loading="listLoading" show-summary>
      <el-table-column
        align="left"
        label="薪酬类型"
        min-width="240"
      >
        <template #default="{row}">
          <span >{{row.wageType}}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="薪酬所属期"
        min-width="150"
      >
        <template #default="{row}">
          <span >{{
            row.period
          }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="count" label="人数" min-width="150">
        <template #default="{row}">
          <span >{{
            row.count
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="工资合计"
        min-width="150"
      >
        <template #default="{row}">
          <span >{{((row?.amount1 || 0) + (row?.amount2 || 0)).toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="amount3"
        label="税款"
        min-width="150"
      >
        <template #default="{row}">
          <span >{{row.amount3?.toFixed(2)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="操作"
        min-width="180"
      >
        <template #default="scope">
          <el-button
            @click="editTax(scope.row,'edit')"
            size="small"
            type="text"
            v-if="scope.row.wageType =='正常工资薪资' "
            >编辑</el-button
          >
          <el-button
            @click="editTax(scope.row,'check')"
            size="small"
            type="text"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="dialogTableVisible"
    width="90%"
    :before-close="handleClose"
    destroy-on-close
    :title="comName"
  >
    <normal
      ref="normal"
      v-if="type == '正常工资薪资' && operation == 'check'"
      :comId="comId"
      :period="period"
      :sheetId="sheetId"
    />
    <salaryWage
      ref="salaryWage"
      v-if="type == '正常工资薪资' && operation == 'edit'"
      :comId="comId"
      :period="period"
      :sheetId="sheetId"
      @success="getList"
    />
    <services
      ref="services"
      v-if="type == '劳务报酬所得'"
      :comId="comId"
      :period="period"
      :sheetId="sheetId"
    />
    <bonus
      ref="bonus"
      v-if="type == '全年一次性奖金'"
      :comId="comId"
      :period="period"
      :sheetId="sheetId"
    />
  </el-dialog>
</template>

<script>
import { salarySheetList } from "@/api/salary.js";
import services from "../otherTable/services.vue";
import bonus from "../otherTable/bonus.vue";
import salaryWage from '../../../autoHome/components/salaryWage.vue'
import normal from "../otherTable/normal.vue";
export default {
  name: "",
  components: {
    services,
    bonus,
    salaryWage,
    normal
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
      type: "",
      dialogTableVisible: false,
      comId: null,
      period: "",
      sheetId:null,
      listLoading:false,
      comName:"",
      operation:"edit"
    };
  },
  methods: {
    init(id, period,name) {
      this.comId = id;
      this.period = period;
      this.dialogVisible = true;
      this.comName = name
      this.getList();
    },
    getList() {
      this.listLoading = true
      let param = {
        comId: this.comId,
        period: this.period,
      };
      salarySheetList(param).then((res) => {
        this.listLoading = false
        this.list = res.data.data.list ? res.data.data.list : [];
      });
    },
    editTax(row,flag) {
      this.type = row.wageType;
      this.sheetId = row.id
      this.operation = flag
      this.$nextTick(()=>{
        this.dialogTableVisible = true;
      })
    },
  
  },
};
</script>

<style scoped lang="less"></style>
